import React from 'react';
import SEO from '../components/seo';

import Layout from '../components/en';

const ProductConfirmation = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Order Confirmation - Furlan Snowboards"
      description="Your order is now complete!"
      pathname="/en/product-confirmation/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="jumbotron">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5">
            <h1 className="text-center mt-5">Finalise order</h1>
            <p className="lead text-center">
              Payment is now complete, we will confirm you it's reception shortly.
              Please, complete your order.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container mb-5">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 mb-5">
          <form
            method="post"
            name="order-en"
            action="/en/success"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="order-en" />
            <div className="form-group">
              <label>Phone number (required)</label>
              <input
                className="form-control form-control-lg"
                type="text"
                required
                name="phone"
                id="phone"
                placeholder="+33 ..."
              />
            </div>
            <div className="form-group">
              <label>Shipping adress (if different from billing)</label>
              <textarea
                className="form-control form-control-lg"
                name="shipping"
                id="shipping"
                rows="5"
                placeholder="Your adress ..."
              />
            </div>
            <div className="form-group">
              <label>Promo Code</label>
              <input
                className="form-control form-control-lg"
                type="text"
                name="promo"
                id="promo"
                placeholder="Promo code ..."
              />
            </div>
            <button className="btn btn-warning btn-lg mt-3 mr-3" type="submit">
              Finalise
            </button>
            <input
              className="btn btn-primary btn-lg mt-3"
              type="reset"
              value="Clear"
            />
          </form>
        </div>
      </div>
    </div>
  </Layout>
);

export default ProductConfirmation;
